@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Neuton:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 62.5%; /* scaling for rem units st 1rem = 10px in normal desktop view*/
}

h5 {
  font-size: 2rem;
}

p {
  font-size: 0.8rem;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Balsamiq sans", cursive;
}

/* html sections: */
body {
  height: 100vh;
  width: 100%;
}

h1 {
  font-family: "Balsamiq sans", cursive;
}

p {
  font-size: 1.4rem;
  font-family: "Neuton", serif;
}

label {
  font-family: "Neuton", serif;
  font-size: 1.2rem;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 90%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100vh;
  flex-wrap: nowrap;
  margin: auto auto 0;
  -webkit-flex: 1 1 100vh;
}

section .section-title {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 10px 0;
}

/* section h1 {
    color: rgb(39, 92, 124);
}

section p {
    color: rgb(134, 174, 219);
} */

.home-section,
.contact-section,
.projects-section,
.experiences-section {
  /* background-color: green; */
}

.projects-section,
.experiences-section {
  display: block;
}

/* custom and bootstrap classes: */
.container {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}

.required {
  color: rgba(255, 0, 0, 1);
}

/* navbar styling: */
.navbar {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  width: 100%;
}

.bg-light {
  background-color: rgba(129, 177, 207, 0.8) !important;
  /* background-color: rgba(250, 70, 89, 0.8) */
}

.navbar-brand,
.navbar-light,
.navbar-nav,
.nav-link,
.nav-item {
  color: #fff !important;
}

.navbar-brand {
  font-weight: 600;
  font-size: 1.8rem;
}

.nav-item {
  font-size: 1rem;
}

/* home section styling: */
.profile-pic {
  width: 14rem;
  margin-bottom: 3rem;
}

/* projects section styling: */
.project-button {
  background: rgba(118, 159, 205, 1);
  text-align: center;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  color: white;
  font-weight: 900;
  text-decoration: none;
}

.project-button:hover {
  background: rgb(55, 95, 141);
  color: white;
}
.project-logo {
  height: 1rem;
  display: inline-block;
}

/* contact section: */
#contact-form {
  width: 80%;
}

.form-group {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
}
.contact-label {
  position: relative;
  left: 0;
  text-align: left;
}

.contact-button {
  background: rgba(118, 159, 205, 1);
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 3rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 900;
  text-decoration: none;
  color: white;
  border-radius: 18px;
  border: none;
  margin-top: 10px;
}

.contact-button:hover {
  background: rgb(55, 95, 141);
  color: white;
}

.contact-button:disabled {
  background-color: rgba(118, 159, 205, 0.4);
}

.contact-button:disabled:hover {
  background-color: rgba(118, 159, 205, 0.4);
}

/* footer section styling: */
.footer-logo {
  display: inline-block;
  margin: 0 0.7rem;
  font-size: 2.4rem;
  color: #6699d3;
}

.footer-logo:hover {
  color: #1c4c83;
}

.timeline body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.timeline {
  top: 0px;
  position: relative;
  margin: 50px auto;
  /* max-width: 1000px; */
  /* adding overflow auto such that block height follows content: */
  /* overflow: auto; */
}

/* timeline middle grey line: */
.timeline:before {
  content: " ";
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  background: #c5c5c5;
  z-index: -1;
}

.timeline ul {
  margin: 0;
  padding: 0;
}

/* maintain width at 50% to accomodate timeline line: */
.timeline ul li {
  list-style: none;
  position: relative;
  width: 50%;
  padding: 20px 40px;
  box-sizing: border-box;
}

.timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}

.timeline ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
}

.content {
  padding-bottom: 20px;
}

/* colored timeline dots: */
.timeline ul li:nth-child(odd) .timeline-icon {
  position: absolute;
  top: 20px;
  right: -17px;
  font-size: 30px;
  color: #769fcd;
  background-color: #fff;
  padding: 3px 0 0;
}

.timeline ul li:nth-child(even) .timeline-icon {
  position: absolute;
  top: 20px;
  left: -15px;
  font-size: 30px;
  color: #769fcd;
  background-color: #fff;
  padding: 3px 0 0;
}

/* timeline content : */
.timeline ul li h5 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  color: #769fcd;
}

.timeline ul li p {
  margin: 10px 0 0; /* values: top horizontal bottom */
  padding: 0;
  font-size: 16px;
}

/* timeline time titles: */
.timeline ul li .time h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.timeline ul li:nth-child(odd) .time {
  position: absolute;
  top: 18px;
  right: -165px;
  margin: 0;
  padding: 8px 16px;
  background: #769fcd;
  color: #fff;
  border-radius: 18px;
}

.timeline ul li:nth-child(even) .time {
  position: absolute;
  top: 20px;
  left: -165px;
  margin: 0;
  padding: 8px 16px;
  background: #769fcd;
  color: #fff;
  border-radius: 18px;
}

/* responsive timelines: */
@media (max-width: 1000px) {
  .timeline {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .timeline body {
    height: 100vh;
    padding-top: 200px;
  }

  .timeline {
    width: 100%;
    padding-bottom: 0;
  }

  /* timeline vertical line: */
  .timeline:before {
    left: 40px;
    top: 50px;
  }

  .timeline ul li {
    top: 100px;
  }

  /* timeline experience boxes: */
  .timeline ul li:nth-child(odd),
  .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: left;
    padding: 35px 90px 60px 90px;
  }

  /* timeline icons: */
  .timeline ul li:nth-child(even) .timeline-icon,
  .timeline ul li:nth-child(odd) .timeline-icon {
    top: -18px;
    left: 26px;
  }

  /* timeline date blocks: */
  .timeline ul li:nth-child(odd) .time,
  .timeline ul li:nth-child(even) .time {
    top: -20px;
    left: 90px;
    right: inherit;
  }
}

