body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 62.5%; /* scaling for rem units st 1rem = 10px in normal desktop view*/
}

h5 {
  font-size: 2rem;
}

p {
  font-size: 0.8rem;
}
