@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Neuton:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Balsamiq sans", cursive;
}

/* html sections: */
body {
  height: 100vh;
  width: 100%;
}

h1 {
  font-family: "Balsamiq sans", cursive;
}

p {
  font-size: 1.4rem;
  font-family: "Neuton", serif;
}

label {
  font-family: "Neuton", serif;
  font-size: 1.2rem;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 90%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100vh;
  flex-wrap: nowrap;
  margin: auto auto 0;
  -webkit-flex: 1 1 100vh;
}

section .section-title {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 10px 0;
}

/* section h1 {
    color: rgb(39, 92, 124);
}

section p {
    color: rgb(134, 174, 219);
} */

.home-section,
.contact-section,
.projects-section,
.experiences-section {
  /* background-color: green; */
}

.projects-section,
.experiences-section {
  display: block;
}

/* custom and bootstrap classes: */
.container {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}

.required {
  color: rgba(255, 0, 0, 1);
}

/* navbar styling: */
.navbar {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  width: 100%;
}

.bg-light {
  background-color: rgba(129, 177, 207, 0.8) !important;
  /* background-color: rgba(250, 70, 89, 0.8) */
}

.navbar-brand,
.navbar-light,
.navbar-nav,
.nav-link,
.nav-item {
  color: #fff !important;
}

.navbar-brand {
  font-weight: 600;
  font-size: 1.8rem;
}

.nav-item {
  font-size: 1rem;
}

/* home section styling: */
.profile-pic {
  width: 14rem;
  margin-bottom: 3rem;
}

/* projects section styling: */
.project-button {
  background: rgba(118, 159, 205, 1);
  text-align: center;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  color: white;
  font-weight: 900;
  text-decoration: none;
}

.project-button:hover {
  background: rgb(55, 95, 141);
  color: white;
}
.project-logo {
  height: 1rem;
  display: inline-block;
}

/* contact section: */
#contact-form {
  width: 80%;
}

.form-group {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
}
.contact-label {
  position: relative;
  left: 0;
  text-align: left;
}

.contact-button {
  background: rgba(118, 159, 205, 1);
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 3rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 900;
  text-decoration: none;
  color: white;
  border-radius: 18px;
  border: none;
  margin-top: 10px;
}

.contact-button:hover {
  background: rgb(55, 95, 141);
  color: white;
}

.contact-button:disabled {
  background-color: rgba(118, 159, 205, 0.4);
}

.contact-button:disabled:hover {
  background-color: rgba(118, 159, 205, 0.4);
}

/* footer section styling: */
.footer-logo {
  display: inline-block;
  margin: 0 0.7rem;
  font-size: 2.4rem;
  color: #6699d3;
}

.footer-logo:hover {
  color: #1c4c83;
}
